import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const PartnershipsPage = () => (
  <Layout>
    <SEO title="Partnerships" />
    <main>
      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="why-use-e1"
      >
        <section
          id="intro"
          style={{ height: '600px', backgroundImage: `url(${HeroImage})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>Partnerships</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 main-text">
          <p>
            We are always excited to work with individuals and groups who are
            interested in the same vision as us.{' '}
            <Link to="/contact">contact us</Link> anytime with your partnership
            ideas and we can schedule a time to meet.
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default PartnershipsPage;
